import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_alert = _resolveComponent("el-alert")!
  const _component_el_descriptions_item = _resolveComponent("el-descriptions-item")!
  const _component_el_descriptions = _resolveComponent("el-descriptions")!

  return (_openBlock(), _createElementBlock("section", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_el_descriptions, {
        class: "margin-top",
        title: "格布小包仓库地址",
        column: 1,
        size: "medium"
      }, {
        extra: _withCtx(() => [
          _createVNode(_component_el_alert, {
            title: "邮寄或者送货时请勿删除属于您的客户编号",
            type: "warning",
            "show-icon": "",
            closable: false
          })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_el_descriptions_item, { label: "收件人：" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.warehouse.name ? _ctx.warehouse.name : _ctx.warehouse.title), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, { label: "电话：" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.warehouse.phone), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          }),
          _createVNode(_component_el_descriptions_item, { label: "地址：" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.warehouse.state) + _toDisplayString(_ctx.warehouse.city) + _toDisplayString(_ctx.warehouse.address1) + _toDisplayString(_ctx.warehouse.address2 || ''), 1 /* TEXT */)
            ]),
            _: 1 /* STABLE */
          })
        ]),
        _: 1 /* STABLE */
      })
    ])
  ]))
}