
import request from '@/utils/request'
import { defineComponent } from 'vue'
export default defineComponent({
	data() {
		return {
			warehouse: {},
		}
	},
	created() {
		this.getWarehouse()
	},
	methods: {
		async getWarehouse() {
			const { data } = await request
				.get('/v1/merchants/warehouse')
				.catch((e) => {
					return {
						data: [],
					}
				})
			this.warehouse = data[0]
		},
	},
})
